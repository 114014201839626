<template>
    <TabsRoot :default-value="defaultValue">
        <TabsList v-if="!hideSingleTabList || tabIds.length > 1" class="relative flex border-b-2 tabsList">
            <TabsIndicator
                class="absolute left-0 h-[2px] bottom-0 w-[--reka-tabs-indicator-size] translate-x-[--reka-tabs-indicator-position] translate-y-[1px] rounded-full transition-[width,transform] duration-300">
                <div class="bg-[#555BA2] w-full h-full" />
            </TabsIndicator>
            <TabsTrigger
                v-for="tabId of tabIds"
                :key="tabId"
                class="font-semibold text-base data-[state=active]:text-[#555BA2] flex-1 outline-none cursor-pointer focus-visible:relative pointer-events-auto"
                :value="tabId">
                <slot :name="`trigger-${tabId}`"></slot>
            </TabsTrigger>
        </TabsList>
        <TabsContent v-for="tabId of tabIds" :key="tabId" :value="tabId" class="focus-visible:outline-none">
            <slot :name="`content-${tabId}`"></slot>
        </TabsContent>
    </TabsRoot>
</template>

<script setup>
/**
 * Tabs component that dynamically creates slots based on tabId.
 * ie. if you supply tabIds = ['insight', 'memories']
 * then you supply slots like:
 *   <template #trigger-insight>...</template>
 *   <template #content-insight>...</template>
 *   <template #trigger-memories>...</template>
 *   <template #content-memories>...</template>
 */
import { TabsContent, TabsIndicator, TabsList, TabsRoot, TabsTrigger } from "reka-ui";
import { computed } from "vue";

const { tabIds } = defineProps({
    tabIds: { type: Array, required: true }, // String[]
    hideSingleTabList: { type: Boolean, default: false },
});

const defaultValue = computed(() => tabIds[0]);
</script>

<style scoped></style>
